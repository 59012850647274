.content {
    position: relative;
    width: 100%;
}

.spinnerBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    min-height: 150px;
}