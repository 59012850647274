.content {
    height: 100%;
}

.pageTitle {
    padding: 32px 40px;
    background-color: #FFFFFF;
    font-size: 36px;
    font-weight: 700;
    color: #333333;
}

.tableContent {
    margin: 24px 40px 60px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
}

.input {
    max-width: 423px;
    height: 40px;
}