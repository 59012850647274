.task {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: 0 auto;
    max-width: 600px;
}

.loaderContainer {
    padding-top: 72px;
}

.backLinkText {
    font-size: 20px;
    color: #666666;
}

.backLink {
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 4px;
}

.backLink:hover,
.backLink:hover .backLinkText,
.backLink:hover svg path  {
    color: #FF6633;
    fill: #FF6633;
    text-decoration: underline;
}

.card {
    width: 100%;
    padding: 24px 32px;
    border-radius: 16px;
    border: 1px solid #F3F3F3;
}

.cardContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;
}

.taskId {
    font-size: 24px;
    font-weight: 400;
    line-height: 24px;
    color: #999999;
}

.taskTitle {
    font-size: 32px !important;
    font-weight: 700;
    line-height: 48px;
    color: #333333;
    margin: 0 !important;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: #f0f0f0;
}

.subInfo {
    margin-top: 12px;
}

.emptyBlock {
    margin: 32px auto 0;
}
