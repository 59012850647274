.layout {
  height: 100%;
  min-height: 100vh;
  display: grid;
  max-width: 100%;
  margin: 0 auto;
  background-color: #F3F3F3;
  grid-template-columns: auto;
  grid-template-rows: auto 1fr;
  justify-content: unset;
  align-items: unset;
  column-gap: 10px;
  grid-template-areas:
  'header'
  'main'
}

.header {
  grid-area: header;
  background-color: #222222;
  padding: 17px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main {
  grid-area: main;
}

.companyName {
  color: #CCCCCC;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.7px;
}

.logo {
  cursor: pointer;
}