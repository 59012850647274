.errorPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding-top: 180px;
}

.textContent {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.title {
    font-size: 36px;
    font-weight: 700;
    color: #333333;
    margin: 0;
}

.description {
    font-size: 20px;
    font-weight: 400;
    color: #999999;
    margin: 0;
}
