.subInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.title {
    font-size: 20px;
    font-weight: 400;
}

.value {
    font-size: 20px;
    font-weight: 400;
    color: #333333;
}

.addedValue {
    font-size: 20px;
    font-weight: 400;
    color: #666666;
}

.values {
    display: flex;
    gap: 4px;
}