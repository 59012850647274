.customTable {
    border-radius: 8px;
}

.customColumn {
    color: #999999;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.customTableHeader {
    color: #999999;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.customTableRow {
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.taskLink {
    color: #333333;
    text-decoration: underline;
}

.taskLink:hover {
    color: #FF6633;
    text-decoration: underline;
}